.doubt-list,
.doubt-answer-list {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 200px;
}

.doubt-answer-list {
  padding-bottom: 260px;
  margin-top: 65px;
}
